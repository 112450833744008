import { useState, useEffect } from "react";
import { auth } from "./firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import {  useNavigate, useLocation } from "react-router-dom";

//http://localhost:3000?token=fad87g6ge83ff
//10.10.10.145:3000?token=fad87g6ge83ff
//192.168.1.149:3000?token=fad87g6ge83ff

const Login = () => {
    const navigate = useNavigate();
    const [notice, setNotice] = useState("");
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        
        const autoLogin = async () => {
            try {
                await signInWithEmailAndPassword(auth, "jokisam@gmail.com", token);//fad87g6ge83ff
               // await signInWithEmailAndPassword(auth, "jokisam@gmail.com", "fad87g6ge83ff");//fad87g6ge83ff
                setNotice("Ok");
                navigate("/lista"); // Redirect to Lista component
            } catch {
                setNotice("Invalid credentials.");
            }
        };

        autoLogin();
    }, [navigate, location.search]);

    return (
        <div className="container" >
            <div className="row justify-content-center">
                {notice && (
                    <div className="alert alert-warning" role="alert">
                        {notice}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
