import { BrowserRouter, Routes, Route } from "react-router-dom";
import Lista from './Lista';  // Assuming you have a component named Lista in lista.jsx
import Login from './Login';
import Layout from './Layout';




const App = () => {
    return (
      
      <BrowserRouter >
        <Routes>
            <Route path = "/" element = { <Layout></Layout> }>
              <Route index element = { <Login></Login> }></Route>
              <Route path = "/lista" element = { <Lista></Lista> }></Route>
            </Route>
        </Routes>
      </BrowserRouter>
     
    )
  }

  
  
  export default App