import React, { useState, useEffect } from "react";
import { database } from "./firebase"; // Import database from firebase.js
import { ref, onValue, push, update, remove } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "react-bootstrap";

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // JavaScript kuukaudet alkavat 0:sta
  const year = date.getFullYear().toString().substr(-2); // Otetaan vain kaksi viimeistä numeroa vuodesta

  return `${day}.${month}.${year}`;
}

function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const isValidName = (name) => {
  return name && name.length >= 2;
};

const App = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [detail, setDetail] = useState("");
  const [items, setItems] = useState([]);
  const [adderName, setAdderName] = useState("");
  //uudet
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingProductName, setEditingProductName] = useState("");
  const [editingQuantity, setEditingQuantity] = useState("");
  const [editingDetail, setEditingDetail] = useState("");

  const handleItemLongPress = (item) => {
    const shouldDelete = window.confirm(
      "Haluatko varmasti poistaa tämän tuotteen?"
    );
    if (shouldDelete) {
      const itemRef = ref(database, `items/${item.id}`);
      remove(itemRef).then(() => {
        // Tarkistetaan onko lista nyt tyhjä
        if (items.length === 1) {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // User is not authenticated, redirect to login
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleButtonLongPress = (e) => {
    e.preventDefault(); // Estää oletus kontekstivalikon avautumisen.
    const shouldDelete = window.confirm("Poistetaanko lisätyt nimet?");
    if (shouldDelete) {
      localStorage.removeItem("adderName");

      // Päivitä React-tila, jotta muutokset näkyvät heti käyttöliittymässä
      setAdderName("");
    }
  };

  useEffect(() => {
    const savedAdderName = localStorage.getItem("adderName");

    // const savedFetcherName = localStorage.getItem('fetcherName');

    if (savedAdderName) setAdderName(savedAdderName);

    const databaseRef = ref(database, "items");
    onValue(databaseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const itemsArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        itemsArray.sort((a, b) => (a.isChecked === b.isChecked ? 0 : a.isChecked ? 1 : -1));
        setItems(itemsArray);
      }
    });
  }, []);

  const addItem = () => {
    if (productName.length < 2 || quantity < 1) {
      alert("LISÄÄ TUOTE JA MÄÄRÄ!!!");
      return;
    }

    if (!adderName || adderName.trim() === "") {
      const name = capitalizeFirstLetter(prompt("Anna nimesi?"));
      if (!isValidName(name)) {
        alert("Nimi on oltava vähintään 3 merkkiä pitkä!");
        return; // Exit the function without adding the item
      }
      setAdderName(name);
      localStorage.setItem("adderName", name);

      // Add the product to the list after setting the n
      const newItem = {
        productName: capitalizeFirstLetter(productName),
        detail,
        quantity,
        addedDate: formatDate(new Date().toISOString()),
        isChecked: false,
        addedBy: name, // Use the name just entered
        fetchedBy: "",
      };

      push(ref(database, "items"), newItem);
      setProductName("");
      setQuantity("");
      setDetail("");
    } else {
      // If a name is already set, directly add the product
      const newItem = {
        productName: capitalizeFirstLetter(productName),
        detail,
        quantity,
        addedDate: formatDate(new Date().toISOString()),
        isChecked: false,
        addedBy: adderName, // Use the existing name
        fetchedBy: "",
      };

      push(ref(database, "items"), newItem);
      setProductName("");
      setDetail("");
      setQuantity("");
    }
  };

  const updateDate = (itemId, isChecked, originalDate) => {
    const itemRef = ref(database, `items/${itemId}`);

    if (isChecked) {
      update(itemRef, {
        addedDate: originalDate,
        isChecked: false,
        fetchedBy: "",
      });
    } else {
      let fetchedBy = adderName;
      if (!adderName || adderName.trim() === "") {
        const name = capitalizeFirstLetter(prompt("Noutajan nimi?"));
        if (!isValidName(name)) {
          alert("Nimi on oltava vähintään 2 merkkiä pitkä!");
          return;
        }
        fetchedBy = name;
        setAdderName(name);
        localStorage.setItem("adderName", name);
      }

      update(itemRef, {
        isChecked: true,
        fetchedBy,
      });
    }
  };
  const startEditingItem = (item) => {
    setEditingItemId(item.id);
    setEditingProductName(item.productName);
    setEditingQuantity(item.quantity);
    setEditingDetail(item.detail);
  };

  const saveEditedItem = (itemId) => {
    const itemRef = ref(database, `items/${itemId}`);
    update(itemRef, {
      productName: capitalizeFirstLetter(editingProductName),
      detail: editingDetail,
      quantity: editingQuantity,
    });

    // Poista muokkaustila
    setEditingItemId(null);
    setEditingProductName("");
    setEditingQuantity("");
    setEditingDetail("");
  };

  return (
    <div className="back-content">
      <div className="background-image"></div>
      <div
        className="card"
        style={{
          backgroundColor: "transparent",
          color: "white",
          border: "none",
        }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-10 col-lg-4 offset-lg-4">
              <h2
                className="card-title text-center mb-3"
                style={{ color: "white" }}
              >
                Tarvikehankinta
              </h2>
              <div className="d-flex flex-column align-items-center">
                <div className="mb-2 col-12 col-md-7">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Tuote *"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </div>
                <div className="mb-2 col-12 col-md-7">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Lisätietoa"
                    value={detail}
                    onChange={(e) => setDetail(e.target.value)}
                  />
                </div>
                <div className="mb-3 col-12 col-md-7">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Määrä *"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>

                <button
                  className="btn btn-primary"
                  style={{ width: "180px", height: "40px" }}
                  onClick={addItem}
                  onContextMenu={handleButtonLongPress}
                >
                  Lisää
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-lg-5">
          <ul className="list-group list-group-flush">
            {items.map((item) => (
              <li
                key={item.id}
                className={`CustomCardView`}
                style={{ backgroundColor: item.isChecked ? '/* Uusi väri kun isChecked on true */' : '#111214c4' }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleItemLongPress(item);
                }}
              >
                <div className={item.isChecked ? "checkedItem" : ""}></div>
                <input
                  type="checkbox"
                  class="larger-checkbox"
                  checked={item.isChecked}
                  onChange={() =>
                    updateDate(item.id, item.isChecked, item.addedDate)
                  }
                />
                  {item.isChecked ? <span style={{ color: 'white' }}> Noudettu</span> : null}
                {editingItemId === item.id ? (
                  // Muokkaustila
                  <div>
                    <input
                      type="text"
                      placeholder="Tuote *"
                      value={editingProductName}
                      onChange={(e) => setEditingProductName(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Lisätietoa"
                      value={editingDetail}
                      onChange={(e) => setEditingDetail(e.target.value)}
                    />
                    <input
                      type="number"
                      placeholder="Määrä *"
                      value={editingQuantity}
                      onChange={(e) => setEditingQuantity(e.target.value)}
                    />
                    <button onClick={() => saveEditedItem(item.id)}>
                      Tallenna
                    </button>
                  </div>
                ) : (
                  <div
                    style={{ backgroundColor: "transparent", color: "white" }}
                  >
                    <div className={item.isChecked ? "checkedItem" : ""}>
                      <div className="row">
                        <div className="col-md-4 ">
                        <Row className="mb-2 white-background">
                          <div style={{ color: "lightgreen", fontSize: 21 }}>
                            {capitalizeFirstLetter(item.productName)}
                          </div>
                          </Row>
                        </div>
                        <div className="col-md-4">
                          <div style={{ color: "#06bdf5", fontSize: 21 }}>
                            Määrä: {item.quantity}kpl
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div style={{ color: "#fcef61ea" }}>
                            Lisätietoa: {item.detail}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div style={{ color: "#f67af6ea", fontSize: 21 }}>
                            Lisätty: {item.addedDate}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div style={{ color: "#fd9e9eea" }}>
                            Lisääjä: {capitalizeFirstLetter(item.addedBy)}
                          </div>
                        </div>
                        {item.isChecked && (
                          <div className="col-md-2">
                            <div style={{ color: "#b8f0f7ea" }}>
                              Noutanut: {capitalizeFirstLetter(item.fetchedBy)}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary offset-lg-11"
                      style={{
                        backgroundColor: "transparent",
                        color: "red",
                        fontSize: 15,
                      }}
                      onClick={() => startEditingItem(item)}
                    >
                      Muokkaa
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default App;
