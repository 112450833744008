import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBaLtKtYYNUBMx-DENamySYwsJhtBnZZcU",
  authDomain: "tarvikelista-8f5fb.firebaseapp.com",
  databaseURL: "https://tarvikelista-8f5fb-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tarvikelista-8f5fb",
  storageBucket: "tarvikelista-8f5fb.appspot.com",
  messagingSenderId: "930077722104",
  appId: "1:930077722104:web:81edc27434c9c7631e020d"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth,database }